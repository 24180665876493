import config from "../config";
import BaseService from "./BaseService";

class ApiService extends BaseService {
  resourceName = "customers";

  async register(data) {
    const res = await this.call("post", `${config.backendUrl}/${this.resourceName}`, data);

    return res.data ? res.data : {};
  }

}
export default ApiService;
