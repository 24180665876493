const styles = {
    global: {
        palette: {
            primary: {
                main: "#fff",
            },
            secondary: {
                main: "#A10080",
            },
        },
        typography: {
            fontFamily: "Prompt",
        },
    },
    custom: {
        main(theme) {
            return {
                paper: {
                    padding: theme.spacing(2),
                    textAlign: 'center',
                    color: theme.palette.text.secondary,
                },
                center: {
                    textAlign: "center"
                },
                textHeader: {
                    color: "white",
                },
                button: {
                    height: 60,
                    backgroundColor: "#FF0080",
                    color: "#fff"
                },
                input: {
                    display: "none",

                },
                textField: {
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: 'white',
                        },
                        '&:hover fieldset': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'white',
                        },
                        "& .MuiOutlinedInput-input": {
                            color: "white"
                        },
                        "& .MuiInputLabel-root": {
                            color: "white"
                        },
                    },

                },
                contentText: {
                    fontStyle: "italic",
                    color: "#fff"
                },
                imageCover: {
                    backgroundImage: `url(${"https://images.unsplash.com/photo-1533106418989-88406c7cc8ca?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"})`,
                    width: "100%",
                    height: window.innerHeight,
                    top: 0,
                    left: 0,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    objectFit: "inherit"
                },
                fullWidth: {
                    width: window.innerWidth,
                },
                fullHeight: {
                    height: window.innerHeight,
                },
                bgColor: {
                    backgroundImage: ` linear-gradient(45deg, rgba(63, 5, 130, 255), rgba(63, 5, 130, 255), rgba(161, 0, 128, 255))`,
                    minHeight: window.innerHeight
                },
                marginTop_20: {
                    marginTop: 20
                },
                helperText: {
                    color: 'white'
                }

            };
        },

    },
};

export default styles;
