import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, TextField, Button, MenuItem, Link } from '@material-ui/core';
import styles from '../styles';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import provinces from '../provinces.json'
import Swal from 'sweetalert2'
import ApiService from '../services/ApiService';
import history from '../history';
import ButtonBase from '@material-ui/core/ButtonBase';
import NumberFormat from 'react-number-format';
import RegexTextField from "../RegexTextField";
const pjson = require('../../package.json');

const onlyAlphanumericRegex = /[^ก-ฮ0-9]/gi;
const useStyles = makeStyles(styles.custom.main);

const RegisterPage = () => {
    const classes = useStyles();
    const token = "";
    const [imageMileage, setImageMileage] = useState({ base64: "", type: "" });
    const [imageReceipt, setImageReceipt] = useState({ base64: "", type: "" });
    const [imageMileagePreview, setImageMileagePreview] = useState();
    const [imageReceiptPreview, setImageReceiptPreview] = useState();
    const [warrantyId, setWarrantyId] = useState({
        frontLeft: "",
        backLeft: "",
        frontRight: "",
        backRight: ""
    });
    const [vehicleInfo, setVehicleInfo] = useState({
        vehicleRegistration: "",
        province: "",
        brand: "",
        generation: ""
    });
    const [personalInfo, setPersonalInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: ""
    });
    const [dealerInfo, setDealerInfo] = useState({
        dealerName: "",
        dealerProvince: "",
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleUplosdImagesMileage = (event) => {
        if (!event.target.files[0]) {
            return;
        }
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            const imageType = file.name.split('.').pop().toLowerCase();
            setImageMileage({ ...imageMileage, base64: reader.result, type: imageType })
        };
        setImageMileagePreview(URL.createObjectURL(event.target.files[0]));
    };

    const handleUplosdImagesReceipt = (event) => {
        if (!event.target.files[0]) {
            return;
        }
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
            const imageType = file.name.split('.').pop().toLowerCase();
            setImageReceipt({ ...imageMileage, base64: reader.result, type: imageType })
        };
        setImageReceiptPreview(URL.createObjectURL(event.target.files[0]));
    };
    const messageAlert = (icon, title, text) => {
        Swal.fire({
            icon: icon,
            title: title,
            text: text,
        })
    };

    const handleError = (e) => {
        messageAlert('error', 'เกิดข้อผิดพลาด...', e.response ? e.response.data.error : e.message);
    };
    const Authorized = () => {
        localStorage.clear()
        history.push('/');
    };
    const api = new ApiService(token,
        { onError: handleError, onUnAuthorized: Authorized }
    );

    const handleSubmit = async () => {
        if (!warrantyId.frontLeft && !warrantyId.backLeft && !warrantyId.frontRight && !warrantyId.backRight) {
            messageAlert('error', 'ข้อมูลไม่ถูกต้อง...', 'กรุณากรอกรหัสรับประกันล้อรถยนต์อย่างน้อย 1 ช่อง');
            return;
        }
        if (!vehicleInfo.vehicleRegistration) {
            messageAlert('error', 'ข้อมูลไม่ถูกต้อง...', 'กรุณากรอกข้อมูลหมายเลขทะเบียน');
            return;
        }
        if (!vehicleInfo.province) {
            messageAlert('error', 'ข้อมูลไม่ถูกต้อง...', 'กรุณากรอกข้อมูลจังหวัด');
            return;
        }
        if (!personalInfo.firstName) {
            messageAlert('error', 'ข้อมูลไม่ถูกต้อง...', 'กรุณากรอกข้อมูลชื่อ');
            return;
        }
        if (!personalInfo.lastName) {
            messageAlert('error', 'ข้อมูลไม่ถูกต้อง...', 'กรุณากรอกข้อมูลนามสกุล');
            return;
        }
        if (!personalInfo.phoneNumber) {
            messageAlert('error', 'ข้อมูลไม่ถูกต้อง...', 'กรุณากรอกหมายเลขโทรศัพท์');
            return;
        }
        if (!dealerInfo.dealerName) {
            messageAlert('error', 'ข้อมูลไม่ถูกต้อง...', 'กรุณากรอกชื่อร้านค้าตัวแทนจำหน่าย');
            return;
        }
        if (!dealerInfo.dealerProvince) {
            messageAlert('error', 'ข้อมูลไม่ถูกต้อง...', 'กรุณากรอกข้อมูลจังหวัด');
            return;
        }
        if (!imageMileage || !imageMileage.base64 || !imageMileage.type) {
            messageAlert('error', 'ข้อมูลไม่ถูกต้อง...', 'กรุณาใส่รูปเลขไมล์');
            return;
        }
        if (!imageReceipt || !imageReceipt.base64 || !imageReceipt.type) {
            messageAlert('error', 'ข้อมูลไม่ถูกต้อง...', 'กรุณาใส่รูปใบเสร็จ');
            return;
        }
        const body = {
            "frontLeftWheel": warrantyId.frontLeft,
            "backLeftWheel": warrantyId.backLeft,
            "frontRightWheel": warrantyId.frontRight,
            "backRightWheel": warrantyId.backRight,
            "vehicleRegistration": vehicleInfo.vehicleRegistration,
            "province": vehicleInfo.province,
            "brand": vehicleInfo.brand,
            "generation": vehicleInfo.generation,
            "firstName": personalInfo.firstName,
            "lastName": personalInfo.lastName,
            "phoneNumber": personalInfo.phoneNumber,
            "email": personalInfo.email,
            "imageMileage": imageMileage,
            "imageReceipt": imageReceipt,
            "dealerName": dealerInfo.dealerName,
            "dealerProvince": dealerInfo.dealerProvince
        };
        setIsLoading(true);
        const res = await api.register(body);
        setIsLoading(false);
        if (!res || !res._id) {
            messageAlert('error', 'เกิดข้อผิดพลาด...', 'ข้อมูลไม่สามารถบันทึกได้ โปรดติดต่อผู้ดูแลระบบ');
            return;
        }

        Swal.fire({
            title: 'สำเร็จ...',
            text: 'ลงทะเบียนรับประกันเรียบร้อย',
            icon: 'success',
            confirmButtonText: 'ตกลง'
        }).then((result) => {
            console.log(result);
            if (result.isConfirmed) {
                localStorage.clear();
                history.push('/');
            }
        });
    };


    return (
        <div className={classes.bgColor}>
            <React.Fragment>
                <CssBaseline />
                <Grid item>
                    <ButtonBase >
                        <img style={{ maxHeight: "100%", maxWidth: "100%", width: "3000px" }} alt="nos- n1 header" src="https://www.nos-n1.com/wp-content/uploads/2021/02/new-banner.png" />
                    </ButtonBase>
                </Grid>
                <Container style={{ paddingTop: 40 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} className={classes.center}>
                            <Typography variant="h3" style={{ color: "white", fontStyle: "italic", fontWeight: 900 }} >WARRANTY (รับประกัน 120 วัน เคลม 100%*)</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography
                                        className={classes.textHeader}
                                        variant="h5"
                                        gutterBottom>
                                        Serial Number ยาง
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="ล้อหน้าซ้าย"
                                        variant="outlined"
                                        placeholder="ตัวอย่าง DOT 045 CD5K4P 0121"
                                        fullWidth
                                        inputProps={{ style: { color: 'white' } }}
                                        InputLabelProps={{ style: { color: '#fff' } }}
                                        className={classes.textField}
                                        value={warrantyId.frontLeft}
                                        onChange={e => setWarrantyId({ ...warrantyId, frontLeft: e.target.value })} />

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="ล้อหน้าขวา"
                                        variant="outlined"
                                        placeholder="ตัวอย่าง DOT 045 CD5K4P 0121"
                                        fullWidth
                                        inputProps={{ style: { color: 'white' } }}
                                        InputLabelProps={{ style: { color: '#fff' } }}
                                        className={classes.textField}
                                        value={warrantyId.frontRight}
                                        onChange={e => setWarrantyId({ ...warrantyId, frontRight: e.target.value })} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="ล้อหลังซ้าย"
                                        variant="outlined"
                                        placeholder="ตัวอย่าง DOT 045 CD5K4P 0121"
                                        fullWidth
                                        inputProps={{ style: { color: 'white' } }}
                                        InputLabelProps={{ style: { color: '#fff' } }}
                                        className={classes.textField}
                                        value={warrantyId.backLeft}
                                        onChange={e => setWarrantyId({ ...warrantyId, backLeft: e.target.value })} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="ล้อหลังขวา"
                                        variant="outlined"
                                        placeholder="ตัวอย่าง DOT 045 CD5K4P 0121"
                                        fullWidth
                                        inputProps={{ style: { color: 'white' } }}
                                        InputLabelProps={{ style: { color: '#fff' } }}
                                        className={classes.textField}
                                        value={warrantyId.backRight}
                                        onChange={e => setWarrantyId({ ...warrantyId, backRight: e.target.value })} />
                                </Grid>
                                <Grid item xs style={{ paddingTop: 5 }} >
                                    <img style={{ maxHeight: "100%", maxWidth: "100%" }} src="https://www.nos-n1.com/wp-content/uploads/2021/02/1-scaled.jpg" alt="nos-n1 sn" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography className={classes.textHeader} variant="h6" gutterBottom>ข้อมูลส่วนตัว</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        label="ชื่อ"
                                        required
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{ style: { color: 'white' } }}
                                        InputLabelProps={{ style: { color: '#fff' } }}
                                        className={classes.textField}
                                        value={personalInfo.firstName}
                                        onChange={e => setPersonalInfo({ ...personalInfo, firstName: e.target.value })} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        label="นามสกุล"
                                        required
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{ style: { color: 'white' } }}
                                        InputLabelProps={{ style: { color: '#fff' } }}
                                        className={classes.textField}
                                        value={personalInfo.lastName}
                                        onChange={e => setPersonalInfo({ ...personalInfo, lastName: e.target.value })} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <NumberFormat
                                        customInput={TextField}
                                        helperText="ใส่ตัวเลขเท่านั้น"
                                        FormHelperTextProps={{
                                            className: classes.helperText
                                        }}
                                        format="###-####-###"
                                        label="หมายเลขโทรศัพท์"
                                        required
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{ style: { color: 'white' } }}
                                        InputLabelProps={{ style: { color: '#fff' }, }}
                                        className={classes.textField}
                                        value={personalInfo.phoneNumber}
                                        onChange={e => setPersonalInfo({ ...personalInfo, phoneNumber: e.target.value })} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="อีเมล"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{ style: { color: 'white' } }}
                                        InputLabelProps={{ style: { color: '#fff' } }}
                                        className={classes.textField}
                                        value={personalInfo.email}
                                        onChange={e => setPersonalInfo({ ...personalInfo, email: e.target.value })} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className={classes.textHeader + " " + classes.marginTop_20} variant="h6" gutterBottom>ข้อมูลรถยนต์</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <RegexTextField
                                        id="vehicleInfo"
                                        regex={onlyAlphanumericRegex}
                                        label="หมายเลขทะเบียน"
                                        helperText="ตัวอย่าง 1กข2345 ไม่ต้องเว้นวรรคไม่ต้องใส่จังหวัด"
                                        FormHelperTextProps={{
                                            className: classes.helperText
                                        }}
                                        required
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{ style: { color: 'white' }, pattern: '[a-z]' }}
                                        InputLabelProps={{ style: { color: '#fff' } }}
                                        className={classes.textField}
                                        value={vehicleInfo.vehicleRegistration}
                                        onChange={e => setVehicleInfo({ ...vehicleInfo, vehicleRegistration: e.target.value })}
                                    />

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        labelId="select"
                                        variant="outlined"
                                        id="select"
                                        fullWidth
                                        color="primary"
                                        inputProps={{ style: { color: 'white' } }}
                                        InputLabelProps={{ style: { color: '#fff' } }}
                                        className={classes.textField}
                                        value={vehicleInfo.province}
                                        onChange={e => setVehicleInfo({ ...vehicleInfo, province: e.target.value })}
                                        label="จังหวัด"
                                        select>
                                        {provinces.map(province =>
                                            <MenuItem
                                                key={province.PROVINCE_ID}
                                                value={province.PROVINCE_NAME}>
                                                <Typography variant="body2">
                                                    {province.PROVINCE_NAME}
                                                </Typography>
                                            </MenuItem>)}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="ยี่ห้อ"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{ style: { color: 'white' } }}
                                        InputLabelProps={{ style: { color: '#fff' } }}
                                        className={classes.textField}
                                        value={vehicleInfo.brand}
                                        onChange={e => setVehicleInfo({ ...vehicleInfo, brand: e.target.value })} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="รุ่น"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{ style: { color: 'white' } }}
                                        InputLabelProps={{ style: { color: '#fff' } }}
                                        className={classes.textField}
                                        value={vehicleInfo.generation}
                                        onChange={e => setVehicleInfo({ ...vehicleInfo, generation: e.target.value })} />
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} style={{ paddingTop: 27 }}>
                                        <Typography className={classes.textHeader} variant="h6" gutterBottom>ร้านค้าตัวแทนจำหน่าย</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="ชื่อร้านค้าตัวแทนจำหน่าย"
                                            required
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{ style: { color: 'white' } }}
                                            InputLabelProps={{ style: { color: '#fff' }, }}
                                            className={classes.textField}
                                            value={dealerInfo.dealerName
                                            }
                                            onChange={e => setDealerInfo({ ...dealerInfo, dealerName: e.target.value })} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            labelId="select"
                                            variant="outlined"
                                            id="select"
                                            fullWidth
                                            color="primary"
                                            inputProps={{ style: { color: 'white' } }}
                                            InputLabelProps={{ style: { color: '#fff' } }}
                                            className={classes.textField}
                                            value={dealerInfo.dealerProvince}
                                            onChange={e => setDealerInfo({ ...dealerInfo, dealerProvince: e.target.value })}
                                            label="จังหวัด"
                                            select>
                                            {provinces.map(province =>
                                                <MenuItem
                                                    key={province.PROVINCE_ID}
                                                    value={province.PROVINCE_NAME}>
                                                    <Typography variant="body2">
                                                        {province.PROVINCE_NAME}
                                                    </Typography>
                                                </MenuItem>)}
                                        </TextField>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{ paddingLeft: 12, paddingBottom: 40 }}>
                            <Grid item xs={12} sm={6}>
                                <Typography className={classes.textHeader + " " + classes.marginTop_20} variant="h6" gutterBottom>ข้อมูลรูปภาพ</Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1" color="primary">รูปเลขไมล์</Typography>
                                        <input accept="image/*" className={classes.input} id="icon-button-file1" type="file" onChange={(e) => handleUplosdImagesMileage(e)} />
                                        {imageMileagePreview ?

                                            <label htmlFor="icon-button-file1">
                                                <Grid item xs={6}>
                                                    <img src={imageMileagePreview} height="120px" alt={`img receipt`} />
                                                </Grid>
                                            </label>


                                            : <label htmlFor="icon-button-file1">
                                                <Button variant="contained" color="primary" component="span" startIcon={<PhotoCamera />}>
                                                    เลือกรูปเลขไมล์</Button>
                                            </label>
                                        }
                                    </Grid>
                                    <Grid item xs={6}  >
                                        <Typography variant="subtitle1" color="primary">รูปใบเสร็จ</Typography>
                                        <input accept="image/*" className={classes.input} id="icon-button-file2" type="file" onChange={(e) => handleUplosdImagesReceipt(e)} />
                                        {imageReceiptPreview ?
                                            <label htmlFor="icon-button-file2">
                                                <Grid item xs={6}>
                                                    <img src={imageReceiptPreview} height="120px" alt={`img receipt`} />
                                                </Grid>
                                            </label>
                                            :

                                            <label htmlFor="icon-button-file2">
                                                <Button variant="contained" color="primary" component="span" startIcon={<PhotoCamera />}>
                                                    เลือกรูปใบเสร็จ</Button>
                                            </label>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={12} style={{ marginTop: 50 }}>
                                        <Link href="https://www.nos-n1.com/warranty-term" target="_blank">
                                            <Typography variant="subtitle1" style={{ color: 'white' }}>*กรุณาศึกษาเงื่อนไขการรับประกัน คลิกที่นี่</Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button variant="contained" fullWidth size="large"
                                            className={classes.button}
                                            onClick={() => {
                                                if (isLoading === true) {
                                                    return;
                                                }

                                                handleSubmit();
                                            }} >
                                            {isLoading ? 'กำลังโหลด...' : 'ลงทะเบียน'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        <h4 style={{ color: "#fff" }}>{pjson.version}</h4>
                    </Grid>
                </Container>
            </React.Fragment>
        </div>
    );
}
export default RegisterPage;
