
import React from "react";
import { Router, Route } from "react-router-dom";
import history from "./history";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import styles from "./styles";
import RegisterPage from "./components/RegisterPage";

const theme = createMuiTheme(styles.global);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Route exact path="/" component={RegisterPage} />
      </Router>
    </ThemeProvider>
  );
}

export default App;
